import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { NAFGTMEvent } from '../../../../../../types/GTM/NAFGTMEvent';
import { useGTMDataLayer } from '../../../../hooks/GTM/useGTMDataLayer';
import { SectionBlockType } from '../../../../../../types/CategoryAppType';
import ErrorBoundary from '../../../error-component/ErrorBoundary';
import { SectionBlocks } from '../../index';
import useSelector from '../../../../redux/typedHooks';
import { BaseTabHeaderButton, HeaderBorder, ScrollContainer, SectionContainer, TabHeader } from './Styles';
import { getURLParams } from '../../../../lib/getUrlParams';
import { updateUrlQueryValues } from '../../../../utils/updateUrlQueryValues';

interface TabView {
  title: string;
  slug: string;
  sections: SectionBlockType[];
}

interface Props {
  block: {
    key: string;
    type: string;
    tabViews: TabView[];
  };
}

export const TabBlock = ({ block, block: { tabViews } }: Props) => {
  const [selectedTabSlug, setSelectedTabSlug] = useState(tabViews[0]?.slug);
  const paramName = `tabView`;
  const dataLayer = useGTMDataLayer();
  const location = useLocation();
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const history = useHistory();
  const [trackedInitial, setTrackedInitial] = useState(false);

  const tabViewMap = useMemo(
    () =>
      tabViews.reduce((acc: Record<TabView['slug'], TabView>, cur) => {
        acc[cur.slug] = cur;
        return acc;
      }, {}),
    [tabViews],
  );

  const handleTracking = useCallback(
    (tabSlug: string) => {
      if (!dataLayer || !tabSlug) return;
      const fromTab = tabViews.find((tab) => tab.slug === selectedTabSlug)?.title;
      const toTab = tabViews.find((tab) => tab.slug === tabSlug)?.title;

      if (block.type === 'tabsSectionMembershipBenefit') {
        if (fromTab === toTab && trackedInitial) return;

        dataLayer.push({
          event: NAFGTMEvent.virtualPageView,
          page: `${history.location.pathname}/${tabSlug}`,
          pageUrl: `${baseUrl}${history.location.pathname}${history.location.search}`,
          pageTitle: `${document.title} - ${toTab}`,
        });

        setTrackedInitial(true);

        return;
      }

      dataLayer.push({
        event: NAFGTMEvent.tabNavigation,
        tabNavigation: {
          from: fromTab || 'UNDEFINED',
          to: toTab || 'UNDEFINED',
        },
        page: location.pathname,
        pageUrl: `${baseUrl}${location.pathname}`,
      });
    },
    [
      baseUrl,
      block.type,
      dataLayer,
      history.location.pathname,
      history.location.search,
      location.pathname,
      selectedTabSlug,
      tabViews,
      trackedInitial,
    ],
  );

  useEffect(() => {
    const params = getURLParams();
    if (
      typeof params[paramName] !== 'undefined' &&
      setSelectedTabSlug &&
      paramName &&
      typeof params[paramName] === 'string'
    ) {
      handleTracking(params[paramName] as string);
      setSelectedTabSlug(params[paramName] as string);
    } else {
      handleTracking(tabViews[0]?.slug);
    }
  }, [setSelectedTabSlug, paramName, handleTracking, tabViews]);

  useEffect(() => {
    const params = getURLParams();
    if (typeof params[paramName] === 'string') {
      setSelectedTabSlug(params[paramName] as string);
    }
  }, [location, paramName]);

  const handleHeaderClick = (e: React.MouseEvent<HTMLButtonElement>, tabSlug: string) => {
    e.preventDefault();
    handleTracking(tabSlug);
    setSelectedTabSlug(tabSlug);
    updateUrlQueryValues.updateGenericParam(tabSlug, paramName);
  };

  return (
    <div>
      <ScrollContainer>
        <HeaderBorder>
          <TabHeader role="tablist">
            {tabViews.map((tab) => (
              <BaseTabHeaderButton
                key={tab.slug}
                $active={selectedTabSlug === tab.slug}
                type="button"
                onClick={(e) => handleHeaderClick(e, tab.slug)}
                role="tab"
                aria-selected={selectedTabSlug === tab.slug}
                aria-controls={tab.title}
                id={tab.slug}
              >
                {tab.title}
              </BaseTabHeaderButton>
            ))}
          </TabHeader>
        </HeaderBorder>
      </ScrollContainer>
      <ErrorBoundary>
        <SectionContainer role="tabpanel" aria-labelledby={`tab-${tabViews[selectedTabSlug]?.title}`}>
          <SectionBlocks content={tabViewMap[selectedTabSlug]?.sections || []} />
        </SectionContainer>
      </ErrorBoundary>
    </div>
  );
};
